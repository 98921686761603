<template>
  <div class="setting">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">编辑资料</div>
      <div class="rightBtn"></div>
    </div>

    <div class="main">
      <div class="cellBox">
        <router-link tag="div" to="/editAvatar" class="cellItem">
          <div class="cellTitle">头像</div>
          <div class="cellContent">
            <ImgDecypt
              class="avatarImg"
              :src="userInfo.portrait"
              :key="userInfo.portrait"
              round
              v-if="userInfo.portrait"
            />
          </div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </router-link>
        <router-link tag="div" to="/editBackground" class="cellItem">
          <div class="cellTitle">更换背景</div>
          <div class="cellContent"></div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </router-link>
        <router-link
          tag="div"
          :to="`/editName?n=${userInfo.name}`"
          class="cellItem"
        >
          <div class="cellTitle">昵称</div>
          <div class="cellContent">{{ userInfo.name }}</div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </router-link>
        <div class="cellItem">
          <div class="cellTitle">妖精ID</div>
          <div class="cellContent">{{ userInfo.uid }}</div>
          <div class="cellBtn" @click="copyUrl(userInfo.uid)">复制</div>
        </div>
        <div class="cellItem" @click="changeGender">
          <div class="cellTitle">性别</div>
          <div class="cellContent">{{ handleGender() }}</div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </div>
        <div class="cellItem" @click="changeBirthday">
          <div class="cellTitle">生日</div>
          <div class="cellContent whiteColor">
            {{ userInfo.birthday || "点击设置" }}
          </div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </div>
        <div class="cellItem" @click="changeCity">
          <div class="cellTitle">地区</div>
          <div class="cellContent whiteColor">
            {{ userInfo.region || "点击设置" }}
          </div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </div>
        <router-link tag="div" to="/editIntroduction" class="cellItem">
          <div class="cellTitle">个性签名</div>
          <div class="summary whiteColor">
            {{ userInfo.summary || "填写个性签名" }}
          </div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </router-link>
      </div>
    </div>
    <!-- 性别 -->
    <van-popup class="genderPop" v-model="showGenderSelect" position="bottom">
      <van-picker
        show-toolbar
        :columns="genders"
        :default-index="genderIndex"
        @confirm="confirmGender"
        @cancel="showGenderSelect = false"
      />
    </van-popup>
    <!-- 生日 -->
    <van-popup class="genderPop" v-model="showDateSelect" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :columns-order="['year', 'month', 'day']"
        :formatter="formatterDate"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="showDateSelect = false"
        @confirm="modifyBirthday"
      />
    </van-popup>
    <!-- 地区 -->
    <van-popup class="genderPop" v-model="showCitySelect" position="bottom">
      <van-picker
        show-toolbar
        :columns="cityList"
        @cancel="showCitySelect = false"
        @confirm="modifyCity"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast, Picker, Popup, DatetimePicker } from "vant";
import cityJSON from "@/assets/json/city.json";
import { queryUserInfo, modifyUserInfo } from "@/api/user";
import { setSessionItem } from "@/utils/longStorage";
import ImgDecypt from "@/components/ImgDecypt";
export default {
  name: "setting",
  components: {
    ImgDecypt,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
  },
  data() {
    return {
      businessCooperation: "",
      userInfo: {},
      phoneVal: "",
      genders: ["男", "女", "不公开"],
      showGenderSelect: false,
      gender: "",
      genderIndex: 0,
      showDateSelect: false,
      currentDate: new Date(),
      minDate: new Date(1934, 1, 1),
      maxDate: new Date(),
      cityList: cityJSON.provinces,
      showCitySelect: false,
      currentCity: "",
    };
  },
  created() {
    let appConfig = this.$store.getters.appConfig;
    appConfig.sourceList.forEach((i) => {
      if (i.type == "BusinessCooperation") {
        this.businessCooperation = i.domain[0].url;
      }
    });
    this.queryUserInfo();
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 复制链接
    copyUrl(text) {
      if (text) {
        text = text + "";
        this.$copyText(text).then(
          (e) => {
            Toast("复制成功");
          },
          (e) => {
            Toast("复制失败");
          }
        );
      }
    },
    // 获取用户信息
    async queryUserInfo() {
      let res = await this.$Api(queryUserInfo, {
        uid: 0,
      });
      if (res.code === 200) {
        this.userInfo = res.data;
        setSessionItem("userInfo", JSON.stringify(res.data));
        this.$store.commit("user/SET_USERINFO", res.data);
      }
    },
    // 处理性别展示
    handleGender() {
      let str = "不公开";
      if (this.userInfo.gender == "male") {
        str = "男";
      } else if (this.userInfo.gender == "female") {
        str = "女";
      } else {
        str = "不公开";
      }
      return str;
    },
    // 修改性别
    changeGender() {
      let str = "";
      switch (this.userInfo.gender) {
        case "male":
          str = "男";
          break;
        case "female":
          str = "女";
          break;
        default:
          str = "不公开";
          break;
      }
      this.genders.forEach((item, index) => {
        if (str == item) {
          this.genderIndex = index;
        }
      });
      this.showGenderSelect = true;
    },
    // 确认修改性别
    async confirmGender(val) {
      let genderVal = "";
      if (val == "男") {
        genderVal = "male";
      } else if (val == "女") {
        genderVal = "female";
      }
      let ret = await this.$Api(modifyUserInfo, {
        gender: genderVal,
      });
      if (ret && ret.code == 200) {
        this.queryUserInfo();
        this.showGenderSelect = false;
        Toast("编辑成功");
      } else {
        Toast(ret.tip || "编辑失败，请稍后再试");
      }
    },
    // 格式化日期
    formatterDate(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      return val;
    },
    // 修改生日日期
    changeBirthday() {
      this.currentDate = this.userInfo.birthday
        ? new Date(this.userInfo.birthday)
        : new Date();
      this.showDateSelect = true;
    },
    async modifyBirthday(date) {
      let dateTime = new Date(date);
      let dateStr =
        dateTime.getFullYear() +
        "-" +
        (dateTime.getMonth() + 1) +
        "-" +
        dateTime.getDate();
      let ret = await this.$Api(modifyUserInfo, {
        birthday: dateStr,
      });
      if (ret && ret.code == 200) {
        this.queryUserInfo();
        this.showDateSelect = false;
        Toast("编辑成功");
      } else {
        Toast(ret.tip || "编辑失败，请稍后再试");
      }
    },
    changeCity() {
      this.showCitySelect = true;
    },
    async modifyCity(val) {
      let region = "";
      if (val.length > 1) {
        region = val.join(" ");
      } else {
        region = val[0];
      }
      // console.log(val)
      let ret = await this.$Api(modifyUserInfo, {
        region: region,
      });
      if (ret && ret.code == 200) {
        this.queryUserInfo();
        this.showCitySelect = false;
        Toast("编辑成功");
      } else {
        Toast(ret.tip || "编辑失败，请稍后再试");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.setting {
  height: 100%;
  color: #000;
  background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    // border-bottom: 1px solid #e6e6e6;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 18px;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 12px 16px 0;
    box-sizing: border-box;
    .cellBox {
      background: #fff;
      padding: 0 12px;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: -5px -5px 20px 0px #fff,
        5px 5px 20px 0px rgba(174, 174, 192, 0.5);
      .cellItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        font-size: 14px;
        color: #333;
        border-bottom: 1px solid #e6e6e6;

        .cellContent {
          color: #999;
          flex: 2;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .avatarImg {
            width: 30px;
            height: 30px;

            /deep/ .vanImage {
              background: #00000000 !important;
            }

            /deep/ .van-image__error {
              background: #00000000 !important;
            }
          }
        }
        .summary {
          // flex: 2;
          width: 231px;
          font-size: 12px;
          color: #999;
          margin: 0 auto;
          text-align: right;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .cellBtn {
          width: 50px;
          height: 24px;
          font-size: 12px;
          border-radius: 8px;
          border: 2px solid rgba(0, 0, 0, 0.05);
          background: #f0f0f3;
          box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.1) inset,
            2px 2px 4px 0px #fff inset,
            -2px -2px 2px 0px rgba(255, 255, 255, 0.4),
            2px 2px 2px 0px rgba(174, 174, 192, 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          color: #b09fd9;
        }

        // .whiteColor {
        //     color: #fff;
        // }

        .cellIcon {
          width: 12px;
          height: 12px;
        }

        .copyBtn {
          width: 46px;
          height: 19px;
          background: url("../../../assets/png/copyBtn.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .cellItem:last-child {
        border-bottom: none;
      }
    }
  }

  .genderPop {
    /deep/ .van-picker {
      background: #fff;
    }

    /deep/ .van-picker__mask {
      // background: #130f32;
      // background: rgba(19, 15, 50, 0.5);
      // display: none;
      background-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.8)
        ),
        linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.8)
        ) !important;
    }

    /deep/ .van-picker-column__item {
      // color: #716f84;
      color: #000;
    }

    /deep/ .van-picker-column__item--selected {
      // color: #fff !important;
    }

    /deep/ .van-hairline-unset--top-bottom::after {
      // border: 1px solid red;
      border: none;
    }

    /deep/ .van-picker__cancel {
    }
    /deep/ .van-picker__confirm {
      color: #000;
      font-size: 15px;
    }
  }
}
</style>
